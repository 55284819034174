
function globalVar(){

    // let reactAppPort=3000;
    // let reactAppUrl='http://localhost'+':'+reactAppPort
    // let reactAppUrl='http://imatul.com'+':'+reactAppPort


    let currentUrl=window.location.href.split('/#')[0];
    let reactAppUrl=currentUrl;

//    console.log("config:",window.Configs.apiURL);

    let globalVar={
        restaurantUrl:reactAppUrl+'portfolio/restaurant',
        blogUrl:reactAppUrl+'portfolio/UnderDev',
        game1Url:reactAppUrl+'games/draw',
        game2Url:reactAppUrl+'portfolio/UnderDev',
        game3Url:reactAppUrl+'portfolio/UnderDev',
        game4Url:reactAppUrl+'portfolio/UnderDev',

//        apiURL:'https://MainLoadBalancer-1943251364.ap-south-1.elb.amazonaws.com:',
//        apiURL:'http://localhost:',
//        apiURL:'https://www.imatul.com:',
//        apiURL:'https://nodejs.imatul.com:',
//        apiPort:'4000'

                apiURL : window.Configs.apiURL,
                apiPort : window.Configs.apiPort
    }
    return(globalVar);

}
export default globalVar;